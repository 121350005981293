let qcm = function ($) {

    let $body = $('body');
    /**
     * Run answers
     */
    let onCkecked = function () {
        $('body').on('change', '#module .qcm input[type="checkbox"]', function (e) {
            e.preventDefault();
            $(this).closest('.container').toggleClass('checked');

            let $items = $body.find('#module .qcm input[type="checkbox"]');
            let $btnAnswers = $body.find('#module .qcm .answers a');
            let itemsLength = $items.length;
            let statusAnswer = false;
            let $next = $body.find('#module > section.main > .content > .next');

            $items.each(function (k) {
                if (this.checked) statusAnswer = true;
                if (k === itemsLength - 1 && statusAnswer) {
                    $btnAnswers.removeClass('disabled');
                    $next.removeClass('disabled');
                    saveAnswersUsers();
                } else {
                    $btnAnswers.addClass('disabled');
                    $next.addClass('disabled');
                }
            });
        });

        $('body').on('change', '#module .qcm-multi input[type="checkbox"]', function (e) {
            e.preventDefault();
            $(this).closest('.container').toggleClass('checked');

            let $items = $body.find('#module .qcm-multi input[type="checkbox"]');
            let $btnAnswers = $body.find('#module .qcm-multi .answers a');
            let itemsLength = $items.length;
            let statusAnswer = false;
            let $next = $body.find('#module > section.main > .content > .next');

            $items.each(function (k) {
                if (this.checked) statusAnswer = true;
                if (k === itemsLength - 1 && statusAnswer) {
                    $btnAnswers.removeClass('disabled');
                    $next.removeClass('disabled');
                    saveAnswersUsers(true);
                } else {
                    $btnAnswers.addClass('disabled');
                    $next.addClass('disabled');
                }
            });
        });
    };

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '#module .qcm .answers a', function (e) {
            e.preventDefault();
            let _this = this;

            let $items = $body.find('#module .qcm input[type="checkbox"]');
            let itemsLength = $items.length;
            let statusAnswer = false;

            $items.each(function (k) {
                if (this.checked) statusAnswer = true;
                if (k === itemsLength - 1 && statusAnswer) {
                    $(_this).fadeOut(function () {
                        $(_this).next().fadeIn();
                    });
                }
            });
        });

        $('body').on('click', '#module .qcm-multi .answers a', function (e) {
            e.preventDefault();
            let _this = this;

            let $items = $body.find('#module .qcm-multi input[type="checkbox"]');
            let itemsLength = $items.length;
            let statusAnswer = false;

            $items.each(function (k) {
                if (this.checked) statusAnswer = true;
                if (k === itemsLength - 1 && statusAnswer) {
                    $(_this).fadeOut(function () {
                        $(_this).next().fadeIn();
                    });
                }
            });
        });
    };

    /**
     * Save Answers Users
     */
    let saveAnswersUsers = function (multi) {

        let tabAnswers = [];
        let $inputAnswersUser;
        let $items;

        if (multi) {
            $inputAnswersUser = $body.find('#module .qcm-multi input.answers-user');
            $items = $body.find('#module .qcm-multi input[type="checkbox"]');
        } else {
            $inputAnswersUser = $body.find('#module .qcm input.answers-user');
            $items = $body.find('#module .qcm input[type="checkbox"]');
        }

        let itemsLength = $items.length;

        let cpt = 0;
        $items.each(function (k) {
            if (this.checked) {
                tabAnswers[cpt] = {};
                tabAnswers[cpt].id = $(this).data('id');
                tabAnswers[cpt].title = $(this).val();
                tabAnswers[cpt].name = $(this).attr('name');
                cpt++;
            }

            if (k == itemsLength - 1) {
                $inputAnswersUser.val(JSON.stringify(tabAnswers)).trigger('change');
            }
        });
    };

    return {
        init: function () {
            onCkecked();
            runAnswers();
        }
    };
}(jQuery);

$(document).ready(function () {
    qcm.init();
});